
.contact__locations {
    position: relative;
    z-index: 1;
}

.contact__locations::before, .contact__locations::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -2;
    background: url(/assets/images/home-product-slider-bg.webp) no-repeat center / cover;
}

.contact__locations::before {
    background: none;
    background-color: var(--blue);
    z-index: -1;
    opacity: .9;
}
.locationIcon{
    font-size: 20px;;
}