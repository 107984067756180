.home__products, .home__company {
    position: relative;
    z-index: 1;
}

.home__company::before, .home__company::after, .home__products::before, .home__products::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -2;
    /* background: url(/assets/images/home-product-slider-bg.webp) no-repeat center / cover; */
}

.home__company::before {
    background: url(/assets/images/home-company-info-bg.webp) no-repeat center / cover;
}

.home__company::before, .home__products::after {
    background: none;
    background-color: var(--blue);
    z-index: -1;
    opacity: .7;
}

.home__product__slide {
    transition: all .3s;
}

.home__product__slide:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0, .2);
}

.home__stats {
    position: relative;
}

.home__stat {
    width: 10vw;
    height: 10vw;
    background: var(--blue);
    margin: 0 auto 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
}

.home__company__box {
    padding: 2em;
    color: #fff;
}
.product_img{
    max-height: 300px;
}
.home__stat span{
    font-size: 35px;
}