*{
  font-family: sans-serif;
  font-size: 14px;
}
h2{
  font-weight: bold;
  font-size: 3.0rem;
}
input.form-control:hover, input.form-control:active, input.form-control:focus {
  outline: none;
  box-shadow: none;
}

img {
  max-width: 100%;
}

.cursor {
  cursor: pointer;
}
.header__menu i{
  color: #007bff;
}

.table__products {
  font-size: .8rem;
}

.table__products td {
  vertical-align:middles
}

.table__products button {
  white-space: nowrap;
}

.view__main {
  min-height: 50vh;;
}
p{
  font-size: 16px;
}
.BrainhubCarousel__arrows{
  background-color: var(--blue);
}
.BrainhubCarousel__arrows:hover {
  background-color: var(--blue);
}
.BrainhubCarousel__arrows:hover span {
  border-color: #fff;
}
.ScrollUpButton__Container {
  width: 40px !important;
  height: 40px !important;
  right: 20px !important;
  border-radius: 4px;
  background-color: var(--blue) !important;
  outline: none;
}
.ScrollUpButton__Container:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, .3) inset;
  border-radius: 4px;
  opacity: .6;
  transition-duration: .4s;
}
aside svg path {
  fill: #fff;
  transition: fill .3s ease-out;
}
.modal-header{
   background-color:var(--blue);
   color: #fff;
}
.modal-header .close{
  color: #fff;
  opacity: 1;
  z-index: 999;
  outline: none;
}
.modal-header .close span{
  font-size: 25px;
}
.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover{
   opacity: 1;
}
.table-bordered thead th {
  background: var(--blue);
  color: #fff;
}
#home-tab{
  background: var(--blue);
  color: #fff;
  font-size: 20px;
  text-align: center;
}
.clearRoom{
  flex-wrap: unset;
  display: block;
}
.nounderline {
  text-decoration: none !important
}
.login {
  width: 400px;
  background: #ebebeb;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}
.loginContainer{
  margin-right: -35px;
}

.login_image{
  min-height: 655px;
  opacity: 0.7;
  height: 100%;
}
.login_card{
  width:100%;
}
.welcome_text{
  height: 620px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  font-size: 32px;
  color: #0069d9;
}
.align_middle{
   top: 50%;
    transform: translateY(50%)
}
.logo_image{
  border-radius: 50%;
  height: 50px;
}
#message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
#inner-message {
  margin: 0 auto;
  text-align: center
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #E4E6E8 !important;
  opacity: 1; /* Firefox */
}
.signOut{
  color: #007bff !important;
  cursor: pointer;
}