hr.divider{
    max-width: 12.25rem;
    border-width: .2rem;
    border-color:#fff;
}
.H-100{
    height: 100%;
}
.ColorDisplay{
    color: #000000;
}
.rounded-circle{
    width: 230px !important;
}
.imageCard{
    display: flex;
    justify-content: center;
}