.header__container {
    position: relative;
    z-index: 2;
    position: sticky;
    top: 0;
}

.header__container > .container {
    position: relative;
}
.header__menu {
    flex: 1;
}

.header__menu__navigation {
    display: flex;
    justify-content: space-around;
    flex: 1;
}

.header__menu__navigation li > a {
    transition: all .3s;
    padding: 8px 24px;
    display: block;
    padding-bottom: 0;
    font-size: 16px;
}

.header__menu__navigation li > a.active,
.header__menu__navigation li:hover > a {
    box-shadow: inset 0 6px 0;
    text-decoration: none;
}

.header__search .input-group-text {
    background: #fff;
}

.header__search input {
    border-left: 0;
}


.sub__menu {
    display:none;
    position: absolute;
    left: 0;
    top: 6.2em;
    z-index: 999;
    background: #fff;
    padding-top: 1em;
    /* border: 1px solid #ccc; */
}

.has_menu:hover .sub__menu {
    display:block;
    
}


.has_menu .sub__menu a, .has_menu .sub__menu h5 {
    font-size: small;
}

.sub__menu .shadow {
    box-shadow: none !important;
}

.sub__menu .card-body {
    padding: .5rem;
}
.card-count{
    position: absolute;
    text-align: center;
    border-radius: 7px;
    width: 17px;
    height: 17px;
    background-color: #ff6161;
    border: 1px solid #fff;
    font-weight: 400;
    color: #f0f0f0;
    line-height: 16px;
    font-size: 12px;
    margin-left: -16px;
    margin-top: -10px;
}