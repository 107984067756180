.footer {
    background: var(--blue);
}

.footer h3 {
    color: #fff;
    opacity: .8;
    margin-bottom: 16px;
}


.footer ul {
    padding-left: 0px;
}

.footer li {
    list-style-type: none;
}

.footer a {
    color: #fff;
    opacity: .8;
}

.footer a:hover {
    color: #fff;
    text-decoration: none;
    opacity: 1;
}

.footer nav a {
    margin-right: 24px;
}